<template>
  <b-modal
    id="form-liff-select"
    :visible="showFormLiffSelectModal"
    :title="$attrs.title"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    hide-footer
    no-close-on-backdrop
    @change="(val) => $emit('update:show-form-liff-select-modal', val)"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          {{ $attrs.title }}
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="(busy === false) ? onDiscard() : false"
          />
        </div>
      </template>

      <!-- Modal Footer -->
      <template #modal-footer>
        <!-- Footer: Left Content -->
        <div />

        <!-- Footer: Right Content -->
        <div>
          <!-- Discard -->
          <feather-icon
            icon="XCircleIcon"
            size="17"
            class="ml-75 cursor-pointer"
            @click="(busy === false) ? onDiscard() : false"
          />
        </div>
      </template>
      <!-- Modal: Body -->
      <div>
        <div>
          <b-card
            class="p-0 m-0"
            style="margin-top: 0.8rem !important;"
          >
            <b-card-title style="margin-bottom: 0.4rem !important;">
              <div v-if="formLiffData">
                <small
                  v-if="formLiffData.name !== undefined && formLiffData.name !== ''"
                >{{ `${$t('Select')} ${$t('FormLiff')}` }}</small>
                <b-alert
                  v-if="formLiffData.name !== undefined && formLiffData.name !== ''"
                  show
                  variant="success"
                  style="margin-top: 0.2rem;"
                >
                  <div class="alert-body">
                    <feather-icon
                      class="mr-25"
                      icon="CheckCircleIcon"
                    />
                    <span class="ml-25">{{ formLiffData.name }}</span>
                  </div>
                </b-alert>
              </div>
            </b-card-title>

            <div style="margin: 0rem 2rem 2rem 2rem;">

              <!-- Table Top -->
              <b-row>

                <!-- Per Page -->
                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <label>{{ $t('Entries') }}</label>
                  <v-select
                    v-model="perPage"
                    :options="perPageOption"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1"
                  />
                </b-col>

                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                >
                  <div
                    class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0"
                  >
                    <b-form-input
                      v-model="q"
                      class="d-inline-block mr-1"
                      :placeholder="`${$t('Search')}...`"
                    />
                  </div>
                </b-col>
              </b-row>

            </div>
            <b-table
              ref="refTable"
              :items="formLiffList"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              hover
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              class="position-relative"
              selectable
              select-mode="multi"
            >
              <!-- Column: Name/Create,Update DateTime/ID -->
              <template #cell(id)="data">
                <!-- Name -->
                <div style="margin-top: 0.3rem;">
                  <b-list-group>
                    <b-list-group>
                      <b-list-group-item
                        style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                      >
                        <span style="margin-right: 0.6rem;">
                          {{ $t('Name') }} »
                        </span>
                      </b-list-group-item>
                      <b-list-group-item
                        style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                      >
                        <span style="font-size: 14px;">
                          {{ data.item.name }}
                        </span>
                      </b-list-group-item>
                    </b-list-group>
                  </b-list-group>
                </div>
                <!-- Create Date/Time -->
                <div style="margin-top: 0.3rem;">
                  <b-list-group>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                    >
                      <span style="margin-right: 0.6rem;">
                        {{ $t('Create Date/Time') }} »
                      </span>
                    </b-list-group-item>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                    >
                      <span style="margin-right: 0.4rem;">
                        <feather-icon
                          icon="CalendarIcon"
                          size="14"
                        />
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.create_date_show }}
                      </span>
                      <span style="margin-left: 1rem; margin-right: 0.4rem;">
                        <feather-icon
                          icon="ClockIcon"
                          size="14"
                        />
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.create_time_show }}
                      </span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <!-- Update Date/Time -->
                <div style="margin-top: 0.3rem;">
                  <b-list-group>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                    >
                      <span style="margin-right: 0.6rem;">
                        {{ $t('Update Date/Time') }} »
                      </span>
                    </b-list-group-item>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                    >
                      <span style="margin-right: 0.4rem;">
                        <feather-icon
                          icon="CalendarIcon"
                          size="14"
                        />
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.update_date_show }}
                      </span>
                      <span style="margin-left: 1rem; margin-right: 0.4rem;">
                        <feather-icon
                          icon="ClockIcon"
                          size="14"
                        />
                      </span>
                      <span style="font-size: 14px;">
                        {{ data.item.update_time_show }}
                      </span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <!-- ID -->
                <div style="margin-top: 0.3rem;">
                  <b-list-group>
                    <b-list-group-item
                      style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                    >
                      <span style="margin-right: 0.6rem;">
                        {{ $t('ID') }} »
                      </span>
                      <span style="font-size: 14px;">
                        <b-badge
                          pill
                          :variant="(skin === 'dark')? `light-info` : `light-info` "
                          class="text-capitalize"
                        >
                          {{ data.item.id }}
                        </b-badge>
                      </span>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </template>

              <!-- Column: form -->
              <template #cell(form)="data">
                <div
                  v-if="data.item.form"
                  class="my-1"
                  style="max-width: 620px;"
                >
                  <div
                    class="form-render-wrapper"
                    :class="[data.item.form.formConfig.layoutType === 'H5' ? 'h5-layout' :
                      (data.item.form.formConfig.layoutType === 'Pad' ? 'pad-layout' : '')]"
                  >
                    <VFormRender
                      ref="preForm"
                      :form-json="formJson(data.item.form)"
                      :preview-state="true"
                    >
                      <!-- <div slot="testSlot">
                        aaaa
                      </div> -->
                    </VFormRender>
                  </div>
                </div>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div
                  style="margin-top: 0.3rem;"
                >
                  <b-button
                    v-if="checkSelect(data.item)"
                    size="sm"
                    variant="primary"
                    style="margin-left: 0rem;"
                    @click.stop="onSelect(data.item)"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="14"
                      style="margin-right: 0.4rem;"
                    />
                    <span class="align-middle">{{ $t('Select') }}</span>
                  </b-button>

                  <b-button
                    v-else
                    size="sm"
                    variant="outline-success"
                    style="margin-left: 0rem;"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="14"
                      style="margin-right: 0.4rem;"
                    />
                    <span class="align-middle">{{ $t('Selected') }}</span>
                  </b-button>
                </div>
              </template>

            </b-table>
            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">
                    {{ $t('Showing {from} to {to} of {of} entries',
                          {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                    }}
                  </span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalItem"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
            </div>
          </b-card>
        </div>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCard,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BPagination,
  BAlert,
  BListGroup,
  BListGroupItem,
  BBadge,
  BFormInput,
  BOverlay,
} from 'bootstrap-vue'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import VFormRender from '@/components/form-render/index.vue'
import {
  deepClone,
} from '@/utils/util'
import FormLiffStore from './FormLiffStore'

export default {
  components: {

    BButton,
    BCard,
    BCardTitle,
    BTable,
    BRow,
    BCol,
    BPagination,
    BAlert,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormInput,
    BOverlay,

    vSelect,
    VFormRender,
  },
  model: {
    prop: 'showFormLiffSelectModal',
    event: 'update:show-form-liff-select-modal',
  },
  props: {
    showFormLiffSelectModal: {
      type: Boolean,
      required: true,
    },
    formLiffData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filterSelect: JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-form-liff-select`)),
      skin: store.state.appConfig.layout.skin,
      showOverlay: false,
      busy: false,
      tableColumns: [],
      perPage: 10,
      totalItem: 0,
      currentPage: 1,
      perPageOption: [2, 5, 10, 25, 50, 100],
      sortBy: 'id',
      isSortDirDesc: true,
      locale: 'th',
      q: '',
      shallShowFormLiffSelectFormModal: false,
      formLiffList: [],
    }
  },
  computed: {
    dataMeta() {
      const localItemsCount = this.$refs.refTable ? this.$refs.refTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalItem,
      }
    },
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  watch: {
    currentPage() {
      this.refetchData()
    },
    perPage() {
      this.refetchData()
    },
    locale() {
      this.refetchData()
    },
    q() {
      this.refetchData()
    },
    showFormLiffSelectModal(value) {
      if (value === true) {
        this.getListFormLiffSelect()
      }
    },
  },
  mounted() {
    const msgList = {
      id: `${this.$i18n.t('Name')} » ${this.$i18n.t('ID')}`,
      form: `${this.$i18n.t('FormLiff')}`,
      actions: this.$i18n.t('Actions'),
    }
    this.tableColumns = [
      { key: 'id', sortable: true, label: msgList.id },
      { key: 'form', sortable: false, label: msgList.form },
      { key: 'actions', label: msgList.actions },
    ]
  },
  methods: {
    getListFormLiffSelect() {
      this.showOverlay = true
      const params = {
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        language: this.locale,
        q: this.q,
        lineOaId: this.lineOaId,
      }
      store
        .dispatch('form-liff-store/getListFormLiffSelect', params)
        .then(response => {
          const { list, total } = response.data
          this.formLiffList = list
          this.totalItem = total
          this.showOverlay = false
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    onDiscard() {
      this.$emit('discard-form-liff-select')
      this.$emit('update:show-form-liff-select-modal', false)
    },
    refetchData() {
      this.getListFormLiffSelect()
    },
    onSelect(item) {
      this.busy = true
      this.$emit('update-form-liff-select', { select: item, formLiff: this.formLiffData })
      this.$emit('update:show-form-liff-select-modal', false)
      this.busy = false
    },
    // eslint-disable-next-line no-unused-vars
    checkSelect(item) {
      if (parseInt(item.id, 10) === parseInt(this.formLiffData.id, 10)) {
        return false
      }
      return true
    },
    formJson(form) {
      return {
        widgetList: deepClone(form.widgetList),
        formConfig: deepClone(form.formConfig),
      }
    },
  },
  setup() {
    if (!store.hasModule('form-liff-store')) store.registerModule('form-liff-store', FormLiffStore)
    onUnmounted(() => {
      if (store.hasModule('form-liff-store')) store.unregisterModule('form-liff-store')
    })

    return {}
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.form-liff-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>

<style lang="scss" scoped>
  div.toolbar-container {
    //min-width: 728px;  /* 解决工具按钮栏换行的问题 */
    /* 上一行css有问题，当窗口宽度不足时会把按钮挤出到右边的属性设置区，弃之！ */
  }

  .left-toolbar {
    float: left;
    font-size: 16px;
  }

  .right-toolbar {
    float: right;
    //width: 420px;
    text-align: right;
    overflow: hidden;

    .right-toolbar-con {
      text-align: left;
      width: 600px;
    }

    ::v-deep .el-button--text {
      font-size: 14px !important;
    }
  }

  .el-button i {
    margin-right: 3px;
  }

  .small-padding-dialog {
    ::v-deep .el-dialog__header {
      //padding-top: 3px;
      //padding-bottom: 3px;
      background: #f1f2f3;
    }

    ::v-deep .el-dialog__body {
      padding: 12px 15px 12px 15px;

      .el-alert.alert-padding {
        padding: 0 10px;
      }
    }

    ::v-deep .ace-container {
      border: 1px solid #DCDFE6;
    }
  }

  .dialog-title-light-bg {
    ::v-deep .el-dialog__header {
      background: #f1f2f3;
    }
  }

  .no-box-shadow {
    box-shadow: none;
  }

  .no-padding.el-tabs--border-card {
    ::v-deep .el-tabs__content {
      padding: 0;
    }
  }

  .form-render-wrapper {
    //height: calc(100vh - 142px);
    //all: revert !important; /* 防止表单继承el-dialog等外部样式，未生效，原因不明？？ */
  }

  .form-render-wrapper.h5-layout {
    margin: 0 auto;
    width: 420px;
    border-radius: 15px;
    //border-width: 10px;
    box-shadow: 0 0 1px 10px #495060;
    height: calc(100vh - 142px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .form-render-wrapper.pad-layout {
    margin: 0 auto;
    width: 960px;
    border-radius: 15px;
    //border-width: 10px;
    box-shadow: 0 0 1px 10px #495060;
    height: calc(100vh - 142px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .node-tree-drawer ::v-deep {
    .el-drawer {
      padding: 10px;
      overflow: auto;
    }

    .el-drawer__header {
      margin-bottom: 12px;
      padding: 5px 5px 0;
    }

    .el-drawer__body {
      padding-left: 5px;
    }
  }

  /*.node-tree-scroll-bar {*/
  /*  height: 100%;*/
  /*  overflow: auto;*/
  /*}*/

  .node-tree ::v-deep {
    .el-tree > .el-tree-node:after {
      border-top: none;
    }
    .el-tree-node {
      position: relative;
      padding-left: 12px;
    }

    .el-tree-node__content {
      padding-left: 0 !important;
    }

    .el-tree-node__expand-icon.is-leaf{
      display: none;
    }
    .el-tree-node__children {
      padding-left: 12px;
      overflow: visible !important; /* 加入此行让el-tree宽度自动撑开，超出宽度el-draw自动出现水平滚动条！ */
    }

    .el-tree-node :last-child:before {
      height: 38px;
    }

    .el-tree > .el-tree-node:before {
      border-left: none;
    }

    .el-tree > .el-tree-node:after {
      border-top: none;
    }

    .el-tree-node:before {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:after {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:before {
      border-left: 1px dashed #4386c6;
      bottom: 0px;
      height: 100%;
      top: -10px;
      width: 1px;
    }

    .el-tree-node:after {
      border-top: 1px dashed #4386c6;
      height: 20px;
      top: 12px;
      width: 16px;
    }

    .el-tree-node.is-current > .el-tree-node__content {
      background: #c2d6ea !important;
    }

    .el-tree-node__expand-icon {
      margin-left: -3px;
      padding: 6px 6px 6px 0px;
      font-size: 16px;
    }

  }
</style>
