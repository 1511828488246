import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultSatisfaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_default_satisfaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListSatisfaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_list_satisfaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSatisfaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/get_satisfaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSatisfaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/add_satisfaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editSatisfaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/edit_satisfaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSatisfaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_satisfaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageSatisfactionImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/booking/upload_image_satisfaction_image/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteImageSatisfactionImage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/delete_image_satisfaction_image', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkSatisfaction(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/booking/check_satisfaction', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
