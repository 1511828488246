<template>
  <b-modal
    id="form-satisfaction"
    :visible="shallShowSatisfactionFormModal"
    :title="`${$t('Form')} ${$t('FormSatisfaction.Satisfaction')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-satisfaction-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeSatisfactionForm)} ${$t('FormSatisfaction.Satisfaction')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-satisfaction-form-modal', false) : false"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeSatisfactionForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ $t('Save Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>
                  <b-col cols="6">

                    <b-row>
                      <!-- Field: Name -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="(typeSatisfactionForm === 'Add' || typeSatisfactionForm === 'Edit')"
                          :label="$t('FormSatisfaction.Name')"
                          label-for="satisfaction-name"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('FormSatisfaction.Name')"
                            rules="required"
                          >
                            <b-form-input
                              id="satisfaction-name"
                              v-model="satisfactionData.name"
                              :state="getValidationState(validationContext)"
                              :disabled="busy"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('FormSatisfaction.Name')"
                          label-for="satisfaction-name"
                        >
                          <span class="form-info-box">{{ satisfactionData.name }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <!-- Field: Action -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="(typeSatisfactionForm === 'Add' || typeSatisfactionForm === 'Edit')"
                          :label="$t('FormSatisfaction.Action')"
                          label-for="satisfaction-action"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('FormSatisfaction.Action')"
                            rules="required"
                          >
                            <b-form-input
                              id="satisfaction-action"
                              v-model="satisfactionData.action"
                              :state="getValidationState(validationContext)"
                              :disabled="busy"
                            />
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('FormSatisfaction.Action')"
                          label-for="satisfaction-action"
                        >
                          <span class="form-info-box">{{ satisfactionData.action }}</span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <!-- Field: Status -->
                      <b-col
                        cols="6"
                      >
                        <b-form-group
                          v-if="(typeSatisfactionForm === 'Add' || typeSatisfactionForm === 'Edit')"
                          :label="$t('Status')"
                          label-for="satisfaction-status"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('Status')"
                            rules="required"
                          >
                            <b-form-radio
                              v-model="satisfactionData.status"
                              name="satisfaction-status"
                              value="active"
                              class="custom-control-success cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Active') }}</span>
                            </b-form-radio>
                            <b-form-radio
                              v-model="satisfactionData.status"
                              name="satisfaction-status"
                              value="inactive"
                              class="custom-control-info cursor-pointer"
                              inline
                            >
                              <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                            </b-form-radio>
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('Status')"
                          label-for="satisfaction-status"
                        >
                          <span class="form-info-box">
                            <b-badge
                              pill
                              :variant="`light-${isStatusVariant(satisfactionData.status)}`"
                              class="text-capitalize"
                            >
                              {{ $t(textFirstUpper(satisfactionData.status)) }}
                            </b-badge>
                          </span>
                        </b-form-group>
                      </b-col>
                    </b-row>

                  </b-col>
                  <b-col cols="6">
                    <!-- Select Form Liff -->
                    <b-row>
                      <!-- Field: Form Liff -->
                      <b-col
                        cols="12"
                      >
                        <b-form-group
                          v-if="(typeSatisfactionForm === 'Add' || typeSatisfactionForm === 'Edit')"
                          :label="$t('FormSatisfaction.FormLiff')"
                          label-for="satisfaction-form-liff"
                        >
                          <validation-provider
                            #default="validationContext"
                            :name="$t('FormSatisfaction.FormLiff')"
                            rules="required"
                          >
                            <b-form-input
                              v-model="satisfactionData.form_liff_id"
                              style="display: none;"
                              :state="getValidationState(validationContext)"
                            />
                            <b-input-group
                              class="m-0"
                              @click.prevent="selectFormLiff"
                            >
                              <b-input-group-prepend
                                is-text
                                style="cursor: pointer;"
                              >
                                <feather-icon
                                  icon="PlusIcon"
                                  size="12"
                                />
                              </b-input-group-prepend>
                              <b-form-input
                                id="satisfaction-form-liff"
                                ref="refChatmessage"
                                v-model="satisfactionData.form_liff_name"
                                :readonly="true"
                                style="background: #f8f8f8 !important; cursor: pointer;"
                                @click="selectFormLiff"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ validationContext.errors[0] }}</small>
                          </validation-provider>

                        </b-form-group>

                        <b-form-group
                          v-else
                          :label="$t('FormSatisfaction.FormLiff')"
                          label-for="satisfaction-form-liff"
                        >
                          <span class="form-info-box">{{ satisfactionData.form_liff_name }}</span>
                        </b-form-group>
                      </b-col>

                    </b-row>
                    <!-- Show Form Liff -->
                    <b-row>
                      <b-col cols="12">
                        <div
                          v-if="satisfactionData.form_liff"
                          class="my-1"
                          style="max-width: 620px;"
                        >
                          <div
                            class="form-render-wrapper"
                            :class="[satisfactionData.form_liff.formConfig.layoutType === 'H5' ? 'h5-layout' :
                              (satisfactionData.form_liff.formConfig.layoutType === 'Pad' ? 'pad-layout' : '')]"
                          >
                            <VFormRender
                              ref="preForm"
                              :form-json="formJson(satisfactionData.form_liff)"
                              :preview-state="true"
                            />
                          </div>
                        </div>
                      </b-col>
                    </b-row>

                  </b-col>
                </b-row>

              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <!-- FormLiff Select -->
    <form-liff-select
      v-model="showFormLiffSelectModal"
      :title="`${$t('Select')} ${$t('FormLiff')}`"
      :form-liff-data="formLiffData"
      @update-form-liff-select="updateFormLiffSelect"
      @discard-form-liff-select="discardFormLiffSelect"
    />

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup, BFormInput,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormRadio,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import FormLiffSelect from '@/views/lineapi/formliff/FormLiffSelect.vue'
import {
  deepClone,
} from '@/utils/util'
import VFormRender from '@/components/form-render/index.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    FormLiffSelect,

    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormRadio,
    BInputGroup,
    BInputGroupPrepend,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    VFormRender,
  },
  model: {
    prop: 'shallShowSatisfactionFormModal',
    event: 'update:shall-show-satisfaction-form-modal',
  },
  props: {
    shallShowSatisfactionFormModal: {
      type: Boolean,
      required: true,
    },
    typeSatisfactionForm: {
      type: String,
      default: 'Add',
    },
    satisfactionData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      skin: store.state.appConfig.layout.skin,
      showFormLiffSelectModal: false,
      formLiffData: {},
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  watch: {
    satisfactionData() {
      this.formLiffData = {
        id: 0,
        name: '',
        form: '',
      }
      if (this.satisfactionData?.form_liff_id
        && this.satisfactionData.form_liff_id !== '') {
        this.formLiffData.id = this.satisfactionData.form_liff_id
        this.formLiffData.name = this.satisfactionData.form_liff_name
        this.formLiffData.form = this.satisfactionData.form_liff
      } else {
        this.satisfactionData.form_liff_id = ''
        this.satisfactionData.form_liff_name = ''
        this.satisfactionData.form_liff = ''
      }
    },
  },
  methods: {
    textFirstUpper(text) {
      return text.slice(0, 1).toUpperCase() + text.slice(1, text.length)
    },
    isStatusVariant(isstatus) {
      if (isstatus === 'active') return 'success'
      if (isstatus === 'inactive') return 'danger'
      return 'danger'
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeSatisfactionForm === 'Add') {
        delete this.satisfactionData.create_datetime
        delete this.satisfactionData.update_datetime
        this.satisfactionData.line_oa_id = this.lineOaId
        store.dispatch('store-satisfaction/addSatisfaction', this.satisfactionData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-satisfaction-form')
            this.$emit('update:shall-show-satisfaction-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeSatisfactionForm === 'Edit') {
        store.dispatch('store-satisfaction/editSatisfaction', { id: this.satisfactionData.id, data: this.satisfactionData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-satisfaction-form')
            this.$emit('update:shall-show-satisfaction-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeSatisfactionForm === 'Delete') {
        store.dispatch('store-satisfaction/deleteSatisfaction', {
          id: this.satisfactionData.id,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-satisfaction-form')
            this.$emit('update:shall-show-satisfaction-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-satisfaction-form')
      this.$emit('update:shall-show-satisfaction-form-modal', false)
    },
    updateFormLiffSelect(data) {
      this.$nextTick(() => {
        this.satisfactionData.form_liff_id = data.select.id
        this.satisfactionData.form_liff_name = data.select.name
        this.satisfactionData.form_liff = data.select.form
        this.formLiffData.id = this.satisfactionData.form_liff_id
        this.formLiffData.name = this.satisfactionData.form_liff_name
        this.formLiffData.form = this.satisfactionData.form_liff

        this.$refs.refForm.focus()
      })
    },
    discardFormLiffSelect() {
    },
    selectFormLiff() {
      if (this.satisfactionData.form_liff_id === '') {
        this.formLiffData = {
          id: 0,
          name: '',
          form: '',
        }
      } else {
        this.formLiffData = {
          id: 0,
          name: '',
          form: '',
        }
        this.formLiffData.id = this.satisfactionData.form_liff_id
        this.formLiffData.name = this.satisfactionData.form_liff_name
        this.formLiffData.form = this.satisfactionData.form_liff
      }
      this.showFormLiffSelectModal = true
    },
    formJson(form) {
      return {
        widgetList: deepClone(form.widgetList),
        formConfig: deepClone(form.formConfig),
      }
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    return {
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
.jsoneditor-poweredBy {
  display: none !important;
}
.jsoneditor {
  border: thin solid #d8d6de !important;
}
.jsoneditor-menu{
  background-color: #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa !important;
}
</style>

<style lang="scss" scoped>
  div.toolbar-container {
    //min-width: 728px;  /* 解决工具按钮栏换行的问题 */
    /* 上一行css有问题，当窗口宽度不足时会把按钮挤出到右边的属性设置区，弃之！ */
  }

  .left-toolbar {
    float: left;
    font-size: 16px;
  }

  .right-toolbar {
    float: right;
    //width: 420px;
    text-align: right;
    overflow: hidden;

    .right-toolbar-con {
      text-align: left;
      width: 600px;
    }

    ::v-deep .el-button--text {
      font-size: 14px !important;
    }
  }

  .el-button i {
    margin-right: 3px;
  }

  .small-padding-dialog {
    ::v-deep .el-dialog__header {
      //padding-top: 3px;
      //padding-bottom: 3px;
      background: #f1f2f3;
    }

    ::v-deep .el-dialog__body {
      padding: 12px 15px 12px 15px;

      .el-alert.alert-padding {
        padding: 0 10px;
      }
    }

    ::v-deep .ace-container {
      border: 1px solid #DCDFE6;
    }
  }

  .dialog-title-light-bg {
    ::v-deep .el-dialog__header {
      background: #f1f2f3;
    }
  }

  .no-box-shadow {
    box-shadow: none;
  }

  .no-padding.el-tabs--border-card {
    ::v-deep .el-tabs__content {
      padding: 0;
    }
  }

  .form-render-wrapper {
    //height: calc(100vh - 142px);
    //all: revert !important; /* 防止表单继承el-dialog等外部样式，未生效，原因不明？？ */
  }

  .form-render-wrapper.h5-layout {
    margin: 0 auto;
    width: 420px;
    border-radius: 15px;
    //border-width: 10px;
    box-shadow: 0 0 1px 10px #495060;
    height: calc(100vh - 142px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .form-render-wrapper.pad-layout {
    margin: 0 auto;
    width: 960px;
    border-radius: 15px;
    //border-width: 10px;
    box-shadow: 0 0 1px 10px #495060;
    height: calc(100vh - 142px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .node-tree-drawer ::v-deep {
    .el-drawer {
      padding: 10px;
      overflow: auto;
    }

    .el-drawer__header {
      margin-bottom: 12px;
      padding: 5px 5px 0;
    }

    .el-drawer__body {
      padding-left: 5px;
    }
  }

  /*.node-tree-scroll-bar {*/
  /*  height: 100%;*/
  /*  overflow: auto;*/
  /*}*/

  .node-tree ::v-deep {
    .el-tree > .el-tree-node:after {
      border-top: none;
    }
    .el-tree-node {
      position: relative;
      padding-left: 12px;
    }

    .el-tree-node__content {
      padding-left: 0 !important;
    }

    .el-tree-node__expand-icon.is-leaf{
      display: none;
    }
    .el-tree-node__children {
      padding-left: 12px;
      overflow: visible !important; /* 加入此行让el-tree宽度自动撑开，超出宽度el-draw自动出现水平滚动条！ */
    }

    .el-tree-node :last-child:before {
      height: 38px;
    }

    .el-tree > .el-tree-node:before {
      border-left: none;
    }

    .el-tree > .el-tree-node:after {
      border-top: none;
    }

    .el-tree-node:before {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:after {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:before {
      border-left: 1px dashed #4386c6;
      bottom: 0px;
      height: 100%;
      top: -10px;
      width: 1px;
    }

    .el-tree-node:after {
      border-top: 1px dashed #4386c6;
      height: 20px;
      top: 12px;
      width: 16px;
    }

    .el-tree-node.is-current > .el-tree-node__content {
      background: #c2d6ea !important;
    }

    .el-tree-node__expand-icon {
      margin-left: -3px;
      padding: 6px 6px 6px 0px;
      font-size: 16px;
    }

  }
</style>
