<template>
  <div
    v-if="$can('read', 'ModuleBooking.Satisfaction')"
    style="height: inherit"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Filters -->
      <satisfaction-list-filters
        :collapsed-filter.sync="collapsedFilter"
        :name-filter.sync="nameFilter"
        :action-filter.sync="actionFilter"
        :status-filter.sync="statusFilter"
        :status-options.sync="statusOptions"
        @reset-filter="resetFilter"
      />

      <!-- Table Container Card -->
      <b-card
        no-body
      >
        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <label>{{ $t('Entries') }}</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end w-100 mt-1 mt-md-0">
                <b-button
                  v-if="$can('create', 'ModuleBooking.Satisfaction')"
                  variant="primary"
                  class="w-50"
                  @click="addSatisfactionForm"
                >
                  {{ `${$t('Add')} ${$t('FormSatisfaction.Satisfaction')}` }}
                </b-button>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1 ml-1"
                  :placeholder="`${$t('Search')}...`"
                />
              </div>
            </b-col>
          </b-row>

        </div>
        <b-table
          ref="refSatisfactionListTable"
          :items="getListSatisfaction"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
          selectable
          select-mode="multi"
        >
          <!-- Column: id -->
          <template #cell(id)="data">
            <!-- ID -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('ID') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-info` : `light-info` "
                      class="text-capitalize"
                    >
                      {{ data.item.id }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Name -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormSatisfaction.Name') }} »
                  </span>
                </b-list-group-item>
                <!-- Name -->
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    {{ data.item.name }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Action -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormSatisfaction.Action') }} »
                  </span>
                </b-list-group-item>
                <!-- Action -->
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    {{ data.item.action }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Create Date/Time -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <!-- Create -->
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ `${$t('Create')} ${$t('Date/Time')}` }} »
                  </span>
                </b-list-group-item>
                <!-- Create Date -->
                <b-list-group-item
                  v-if="data.item.create_date !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="CalendarIcon" />
                    {{ data.item.create_date.name }}
                  </span>
                </b-list-group-item>
                <!-- Create Time -->
                <b-list-group-item
                  v-if="data.item.create_date !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span
                    style="font-size: 14px;"
                  >
                    <feather-icon icon="ClockIcon" />
                    {{ data.item.create_time.name }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Update Date/Time -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <!-- Update -->
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ `${$t('Update')} ${$t('Date/Time')}` }} »
                  </span>
                </b-list-group-item>
                <!-- Update Date -->
                <b-list-group-item
                  v-if="data.item.update_date !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">
                    <feather-icon icon="CalendarIcon" />
                    {{ data.item.update_date.name }}
                  </span>
                </b-list-group-item>
                <!-- Update Time -->
                <b-list-group-item
                  v-if="data.item.update_date !== undefined"
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span
                    style="font-size: 14px;"
                  >
                    <feather-icon icon="ClockIcon" />
                    {{ data.item.update_time.name }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <!-- Status -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <!-- Status -->
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormSatisfaction.Status') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-${isStatusVariant(data.item.status)}` : `light-${isStatusVariant(data.item.status)}` "
                      class="text-capitalize"
                    >
                      {{ $t(textFirstUpper(`${data.item.status}`)) }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Form -->
          <template #cell(form)="data">
            <div
              v-if="data.item.form_liff"
              class="my-1"
              style="max-width: 620px;"
            >
              <div
                class="form-render-wrapper"
                :class="[data.item.form_liff.formConfig.layoutType === 'H5' ? 'h5-layout' :
                  (data.item.form_liff.formConfig.layoutType === 'Pad' ? 'pad-layout' : '')]"
              >
                <VFormRender
                  ref="preForm"
                  :form-json="formJson(data.item.form_liff)"
                  :preview-state="true"
                >
                  <!-- <div slot="testSlot">
                    aaaa
                  </div> -->
                </VFormRender>
              </div>
            </div>
          </template>

          <!-- Column: Satisfaction -->
          <template #cell(satisfaction)="data">
            <!-- Satisfaction Result -->
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem; background-color: #eeeeee;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('FormSatisfacation.SatisfactionResult') }} »
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    {{ $t('Total') }} »
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      variant="light-primary"
                    >
                      {{ data.item.satisfaction_result_total }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                v-if="$can('write', 'ModuleBooking.Satisfaction')"
                :id="`satisfaction-row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer mr-1"
                size="16"
                @click="editSatisfactionForm(data)"
              />
              <b-tooltip
                v-if="$can('write', 'ModuleBooking.Satisfaction')"
                :title="`${$t('Edit')} ${$t('FormSatisfaction.Satisfaction')}`"
                :target="`satisfaction-row-${data.item.id}-edit-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="left"
              />

              <feather-icon
                v-if="$can('read', 'ModuleBooking.Satisfaction')"
                :id="`satisfaction-row-${data.item.id}-view-icon`"
                icon="EyeIcon"
                size="16"
                class="cursor-pointer mr-1"
                @click="viewSatisfactionForm(data)"
              />
              <b-tooltip
                v-if="$can('read', 'ModuleBooking.Satisfaction')"
                :title="`${$t('View')} ${$t('FormSatisfaction.Satisfaction')}`"
                :target="`satisfaction-row-${data.item.id}-view-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="left"
              />

              <feather-icon
                v-if="$can('delete', 'ModuleBooking.Satisfaction')"
                :id="`satisfaction-row-${data.item.id}-delete-icon`"
                icon="TrashIcon"
                variant="light-success"
                size="16"
                class="cursor-pointer mr-1"
                @click="deleteSatisfactionForm(data)"
              />
              <b-tooltip
                v-if="$can('delete', 'ModuleBooking.Satisfaction')"
                :title="`${$t('Delete')} ${$t('FormSatisfaction.Satisfaction')}`"
                :target="`satisfaction-row-${data.item.id}-delete-icon`"
                :delay="{ show: 2500, hide: 50 }"
                placement="right"
              />

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalSatisfactions"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <!-- Form -->
      <satisfaction-form
        v-model="shallShowSatisfactionFormModal"
        :satisfaction-data="satisfactionData"
        :type-satisfaction-form="typeSatisfactionForm"
        @update-satisfaction-form="updateSatisfactionForm"
        @discard-satisfaction-form="discardSatisfactionForm"
      />

    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable, BButton,
  BBadge, BPagination, BTooltip, BListGroup, BListGroupItem,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { $themeConfig } from '@themeConfig'
import VFormRender from '@/components/form-render/index.vue'
import {
  deepClone,
} from '@/utils/util'
import SatisfactionForm from './SatisfactionForm.vue'
import SatisfactionListFilters from './SatisfactionListFilters.vue'
import tableSatisfactionList from './tableSatisfactionList'
import storeSatisfaction from './storeSatisfaction'

export default {
  components: {
    SatisfactionListFilters,
    SatisfactionForm,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,

    vSelect,
    FeatherIcon,
    VFormRender,
  },
  data() {
    return {
      showOverlay: false,
      satisfactionData: {},
      satisfactionFile: {},
      dialog: false,
      skin: store.state.appConfig.layout.skin,
      selected: [],
      collapsedFilter: true,
    }
  },
  mounted() {
    this.getDefaultSatisfaction()
    this.locale = this.$i18n.locale
  },
  methods: {
    getDefaultSatisfaction() {
      this.showOverlay = true
      store
        .dispatch('store-satisfaction/getDefaultSatisfaction', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.satisfactionData = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = true
        })
    },
    addSatisfactionForm() {
      this.showOverlay = true
      store
        .dispatch('store-satisfaction/getDefaultSatisfaction', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.satisfactionData = response.data
          this.typeSatisfactionForm = 'Add'
          this.shallShowSatisfactionFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editSatisfactionForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-satisfaction/getSatisfaction', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.satisfactionData = response.data
          this.satisfactionFile = {
            image: response.data.image,
          }
          this.typeSatisfactionForm = 'Edit'
          this.shallShowSatisfactionFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    viewSatisfactionForm(data) {
      this.showOverlay = true
      store
        .dispatch('store-satisfaction/getSatisfaction', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.satisfactionData = response.data
          this.typeSatisfactionForm = 'View'
          this.shallShowSatisfactionFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deleteSatisfactionForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('store-satisfaction/deleteSatisfaction', { id: data.item.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateSatisfactionForm() {
      this.refetchData()
    },
    discardSatisfactionForm() {
      // this.refetchData()
    },
    resetFilter() {
      this.searchQuery = ''
      this.nameFilter = ''
      this.actionFilter = ''
      this.statusFilter = ''
      const filterList = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-satisfaction-list`))
      if (filterList !== null) {
        this.perPage = (filterList.perPage !== null) ? filterList.perPage : '5'
        this.currentPage = (filterList.page !== null) ? filterList.page : '1'
        this.sortBy = (filterList.sortBy !== null) ? filterList.sortBy : 'id'
        this.isSortDirDesc = (filterList.sortDesc !== null) ? filterList.sortDesc : 'DESC'
      }
    },
    formJson(form) {
      return {
        widgetList: deepClone(form.widgetList),
        formConfig: deepClone(form.formConfig),
      }
    },
  },
  setup() {
    const shallShowSatisfactionFormModal = ref(false)
    const typeSatisfactionForm = ref('')

    // Register module
    if (!store.hasModule('store-satisfaction')) store.registerModule('store-satisfaction', storeSatisfaction)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('store-satisfaction')) store.unregisterModule('store-satisfaction')
    })

    const {
      getListSatisfaction,
      tableColumns,
      perPage,
      currentPage,
      totalSatisfactions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refSatisfactionListTable,
      refetchData,

      isStatusVariant,
      textFirstUpper,

      nameFilter,
      actionFilter,
      statusFilter,

      statusOptions,
    } = tableSatisfactionList()

    const filterList = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-satisfaction-list`))
    if (filterList !== null) {
      searchQuery.value = (filterList.searchQuery !== null && filterList.searchQuery !== undefined) ? filterList.searchQuery : ''
      perPage.value = (filterList.perPage !== null && filterList.perPage !== undefined) ? filterList.perPage : ''
      currentPage.value = (filterList.page !== null && filterList.page !== undefined) ? filterList.page : ''
      sortBy.value = (filterList.sortBy !== null && filterList.sortBy !== undefined) ? filterList.sortBy : ''
      isSortDirDesc.value = (filterList.sortDesc !== null && filterList.sortDesc !== undefined) ? filterList.sortDesc : ''
      nameFilter.value = (filterList.name !== null && filterList.name !== undefined) ? filterList.name : ''
      actionFilter.value = (filterList.action !== null && filterList.action !== undefined) ? filterList.action : ''
      statusFilter.value = (filterList.status !== null && filterList.status !== undefined) ? filterList.status : ''
    }

    return {
      shallShowSatisfactionFormModal,
      typeSatisfactionForm,

      getListSatisfaction,
      tableColumns,
      perPage,
      currentPage,
      totalSatisfactions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refSatisfactionListTable,
      refetchData,

      isStatusVariant,
      textFirstUpper,

      nameFilter,
      actionFilter,
      statusFilter,

      statusOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.satisfaction-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '~@core/scss/base/components/variables';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
  .top-bar-title-bg{
    background-color: $theme-dark-modal-header-bg !important;
  }
}
.top-bar-title-bg{
  background-color: $theme-light-modal-header-bg !important;
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>

<style lang="scss" scoped>
  div.toolbar-container {
    //min-width: 728px;  /* 解决工具按钮栏换行的问题 */
    /* 上一行css有问题，当窗口宽度不足时会把按钮挤出到右边的属性设置区，弃之！ */
  }

  .left-toolbar {
    float: left;
    font-size: 16px;
  }

  .right-toolbar {
    float: right;
    //width: 420px;
    text-align: right;
    overflow: hidden;

    .right-toolbar-con {
      text-align: left;
      width: 600px;
    }

    ::v-deep .el-button--text {
      font-size: 14px !important;
    }
  }

  .el-button i {
    margin-right: 3px;
  }

  .small-padding-dialog {
    ::v-deep .el-dialog__header {
      //padding-top: 3px;
      //padding-bottom: 3px;
      background: #f1f2f3;
    }

    ::v-deep .el-dialog__body {
      padding: 12px 15px 12px 15px;

      .el-alert.alert-padding {
        padding: 0 10px;
      }
    }

    ::v-deep .ace-container {
      border: 1px solid #DCDFE6;
    }
  }

  .dialog-title-light-bg {
    ::v-deep .el-dialog__header {
      background: #f1f2f3;
    }
  }

  .no-box-shadow {
    box-shadow: none;
  }

  .no-padding.el-tabs--border-card {
    ::v-deep .el-tabs__content {
      padding: 0;
    }
  }

  .form-render-wrapper {
    //height: calc(100vh - 142px);
    //all: revert !important; /* 防止表单继承el-dialog等外部样式，未生效，原因不明？？ */
  }

  .form-render-wrapper.h5-layout {
    margin: 0 auto;
    width: 420px;
    border-radius: 15px;
    //border-width: 10px;
    box-shadow: 0 0 1px 10px #495060;
    height: calc(100vh - 142px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .form-render-wrapper.pad-layout {
    margin: 0 auto;
    width: 960px;
    border-radius: 15px;
    //border-width: 10px;
    box-shadow: 0 0 1px 10px #495060;
    height: calc(100vh - 142px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .node-tree-drawer ::v-deep {
    .el-drawer {
      padding: 10px;
      overflow: auto;
    }

    .el-drawer__header {
      margin-bottom: 12px;
      padding: 5px 5px 0;
    }

    .el-drawer__body {
      padding-left: 5px;
    }
  }

  /*.node-tree-scroll-bar {*/
  /*  height: 100%;*/
  /*  overflow: auto;*/
  /*}*/

  .node-tree ::v-deep {
    .el-tree > .el-tree-node:after {
      border-top: none;
    }
    .el-tree-node {
      position: relative;
      padding-left: 12px;
    }

    .el-tree-node__content {
      padding-left: 0 !important;
    }

    .el-tree-node__expand-icon.is-leaf{
      display: none;
    }
    .el-tree-node__children {
      padding-left: 12px;
      overflow: visible !important; /* 加入此行让el-tree宽度自动撑开，超出宽度el-draw自动出现水平滚动条！ */
    }

    .el-tree-node :last-child:before {
      height: 38px;
    }

    .el-tree > .el-tree-node:before {
      border-left: none;
    }

    .el-tree > .el-tree-node:after {
      border-top: none;
    }

    .el-tree-node:before {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:after {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:before {
      border-left: 1px dashed #4386c6;
      bottom: 0px;
      height: 100%;
      top: -10px;
      width: 1px;
    }

    .el-tree-node:after {
      border-top: 1px dashed #4386c6;
      height: 20px;
      top: 12px;
      width: 16px;
    }

    .el-tree-node.is-current > .el-tree-node__content {
      background: #c2d6ea !important;
    }

    .el-tree-node__expand-icon {
      margin-left: -3px;
      padding: 6px 6px 6px 0px;
      font-size: 16px;
    }

  }
</style>
