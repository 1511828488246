import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'

export default function tableSatisfactionList() {
  // Use toast
  const toast = useToast()

  const refSatisfactionListTable = ref(null)
  const lineOa = computed(() => store.state.lineapi.lineOa)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('TitleBooking.Satisfaction') }),
    id: `${t('ID')} » ${t('FormSatisfaction.Name')} » ${t('FormSatisfaction.Action')} » ${t('FormSatisfaction.Date')}`,
    form: `${t('FormSatisfaction.Form')}`,
    satisfaction: `${t('FormSatisfaction.Satisfaction')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, label: msgList.id },
    { key: 'form', sortable: true, label: msgList.form },
    { key: 'satisfaction', sortable: true, label: msgList.satisfaction },
    { key: 'actions', label: msgList.actions },
  ]

  const perPage = ref(10)
  const totalSatisfactions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const locale = ref('th')
  const nameFilter = ref('')
  const actionFilter = ref('')
  const statusFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refSatisfactionListTable.value ? refSatisfactionListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSatisfactions.value,
    }
  })

  const refetchData = () => {
    refSatisfactionListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, locale, nameFilter, actionFilter, statusFilter], () => {
    refetchData()
  })

  const getListSatisfaction = (ctx, callback) => {
    const { id } = JSON.parse(lineOa.value)
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
      name: nameFilter.value,
      action: actionFilter.value,
      status: statusFilter.value,
      lineOaId: id,
    }
    store
      .dispatch('store-satisfaction/getListSatisfaction', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalSatisfactions.value = total
        localStorage.setItem(`${$themeConfig.app.session}-filter-satisfaction-list`, JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const isStatusVariant = status => {
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'danger'
    return 'secondary'
  }

  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  const statusOptions = [
    {
      label: t('All'),
      value: '',
    },
    {
      label: t('Active'),
      value: 'active',
    },
    {
      label: t('Inactive'),
      value: 'inactive',
    },
  ]

  return {
    getListSatisfaction,
    tableColumns,
    perPage,
    currentPage,
    totalSatisfactions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refSatisfactionListTable,

    refetchData,
    isStatusVariant,
    textFirstUpper,

    nameFilter,
    actionFilter,
    statusFilter,

    statusOptions,
  }
}
